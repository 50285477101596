/* general 
--themePrimary-color: #0373fc;
  --themePrimary-hover: #2185ff;
  */
:root {
  --font1-color: #000;
  --font2-color: #fff;
  --themePrimary-color: #587c9d;
  --themePrimary-faded: #496783;
  --themePrimary-hover: #789dbf;
  --themeSecondary-color: #4a5563;
  --themeSecondary-transparent: #4a5563f2;
  --red-color: #f05d5e;
  --navbar-height: 4.25rem;
}
@font-face {
  font-family: coolFont;
  src: url(../constants/SparTakus.ttf);
  font-display: swap;
}
body {
  font-family: sans-serif;
  background-color: #fff;
  color: var(--font1-color);
  margin: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  /*background: url('./img/bkg2.png') no-repeat center center/cover;
  background-attachment: fixed;*/
  height: 100vh;
  overflow-x: hidden;
}

html {
  font-size: 16px;
}
a {
  color: var(--font2-color);
  text-decoration: none;
}
.hide-scroll {
  overflow: hidden;
}
.card-shadow {
  -webkit-box-shadow: 0 0.15rem 0.75rem -0.05rem var(--themeSecondary-color);
  box-shadow: 0 0.15rem 0.75rem -0.05rem var(--themeSecondary-color);
}
.img-shadow {
  -webkit-box-shadow: 0 0.1rem 0.3rem -0.1rem #000;
  box-shadow: 0 0.1rem 0.3rem -0.1rem #000;
}
.page-section {
  margin: var(--navbar-height) auto 0 auto;
  z-index: 2;
  position: relative;
  text-align: center;
  padding: 2rem 2% 2rem 2%;
  max-width: 768px;
}

/* contact popup*/
.contact-popup-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 0;
  z-index: 100;
}
.contact-popup-container {
  position: relative;
  margin: auto;
  top: 50%;
  background-color: var(--themeSecondary-color);
  width: 30rem;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  -webkit-box-shadow: 0 0.2rem 1.5rem 0.125rem #444;
  box-shadow: 0 0.2rem 1.5rem 0.125rem #444;
  z-index: 1;
}
.popup-overlay {
  position: fixed;
  left: -10vw;
  top: -10vh;
  width: 120vw;
  height: 120vh;
  background-color: #333333ab;
  z-index: 0;
}
.contact-pp-img-container div {
  position: relative;
  margin: 0 auto;
  border: 0.25rem solid var(--themePrimary-color);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
}
.contact-pp-img-container div img {
  width: 100%;
  height: 100%;
}
.contact-popup-container h1 {
  font-family: coolFont;
  font-size: 1.3rem;
  margin-top: 1.5rem;
  color: var(--font2-color);
}
.contact-popup-container ul {
  list-style: none;
  padding: 0;
  margin-top: 2rem;
}
.contact-popup-container ul li {
  position: relative;
  margin-top: 1.74rem;
  margin-left: 2rem;
  font-size: 1.05rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  color: var(--font2-color);
}
.contact-popup-container ul li img {
  position: absolute;
  margin-left: -2rem;
  height: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.contact-popup-container ul a:hover {
  color: var(--themePrimary-hover);
  fill: #fff;
}
.contact-popup-close-img {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.1rem;
  width: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  opacity: 0.7;
}
.contact-popup-close-img:hover {
  opacity: 1;
}

/* contact btn */
.contact-btn {
  letter-spacing: 0.05rem;
  font-family: coolFont;
  border-width: 0;
  background-color: var(--themePrimary-color);
  font-size: 0.7rem;
  padding: 0.6rem 1.5rem;
  color: var(--font2-color);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.contact-btn:hover {
  background-color: var(--themePrimary-hover);
}

/* Document view */
.doc-view {
  position: absolute;
  top: calc(2 * var(--navbar-height));
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 4rem;
}
/* Footer */
footer {
  background-color: var(--themeSecondary-color);
  padding: 2rem 0;
  width: 100%;
  text-align: center;
  bottom: 0;
  z-index: 1;
}
footer * {
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
footer h2 {
  font-size: 1rem;
  font-family: coolFont;
  margin: 0 0 1.5rem 0;
}
footer h2 * {
  padding: 0.5rem 1rem;
  letter-spacing: 0.1rem;
}
footer h2 *:hover {
  letter-spacing: 0.125rem;
}
footer div {
  display: flex;
  justify-content: center;
}
footer div ul {
  list-style: none;
  align-items: left;
  padding: 0;
  margin: 0;
  width: 20rem;
  max-width: 100%;
  text-align: center;
}
footer div ul li {
  margin: 0.75rem 0;
  color: var(--font2-color);
}
footer div ul li * {
  padding: 0.25rem 0.75rem;
  font-size: 1.1rem;
  margin: 0;
  cursor: pointer;
}
footer div ul li *:hover,
footer h2 *:hover {
  color: var(--themePrimary-hover);
}
.footer-category-title {
  text-decoration: underline;
}
footer div ul li span {
  margin-right: 0.1rem;
}
.namebounce-container {
  position: fixed;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: -10;
}
.namebounce-text-lbl {
  position: absolute;
  opacity: 0.2;
  color: var(--themePrimary-color);
  border: 1px solid transparent;
  font-family: coolFont;
  font-size: 2rem;
  white-space: nowrap;
  z-index: -4;
  margin: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
@media only screen and (max-width: 1000px), screen and (max-height: 1000px) {
  .namebounce-text-lbl {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 900px), screen and (max-height: 900px) {
  .namebounce-text-lbl {
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 800px), screen and (max-height: 800px) {
  .namebounce-text-lbl {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 700px), screen and (max-height: 700px) {
  html {
    font-size: 15px;
  }
  footer {
    padding-bottom: 10rem;
  }
  .namebounce-text-lbl {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 600px), screen and (max-height: 600px) {
  html {
    font-size: 14px;
  }
  .namebounce-text-lbl {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 500px), screen and (max-height: 500px) {
  html {
    font-size: 13px;
  }
  footer div {
    flex-direction: column;
  }
  footer div ul {
    width: 100%;
  }
  .contact-popup-container {
    margin: auto;
    width: 90%;
  }
  .namebounce-text-lbl {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 400px), screen and (max-height: 400px) {
  html {
    font-size: 12px;
  }
  .namebounce-text-lbl {
    font-size: 1.2rem;
  }
}
