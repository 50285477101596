.tl-section {
  text-align: center;
  max-width: 1080px;
  padding: 5rem 0 15rem 0;
}
.tl-header {
}
.current-year {
  color: var(--themeSecondary-color);
  font-family: coolFont;
  font-size: 1.6rem;
}
.tl-main-container {
  display: flex;
  flex-direction: row;
  margin: 0 0.5rem;
}
.tl-left-container,
.tl-right-container {
  flex: 100;
  padding: 0rem 0 2rem 0;
  margin-top: -5rem;
}
.tl-left-container {
  text-align: right;
}
.tl-right-container {
  text-align: left;
  margin-top: 4rem;
}
.tl-line {
  width: 0.4rem;
  background-color: var(--themePrimary-color);
  padding: 0 0 5rem 0;
  border-radius: 2rem;
}
.tl-line-crown {
  position: absolute;
  background-color: var(--themePrimary-color);
  margin-top: -1rem;
  margin-left: -0.5rem;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 1rem;
}
.tl-item-wrapper {
  margin-top: 10rem;
}
.tl-item-container {
  display: flex;
  flex-direction: row;
}
.tl-item-line {
  border-top: 0.2rem solid var(--themePrimary-color);
  flex: 1;
}
.tl-item-year {
  font-weight: bold;
  font-size: 1rem;
  padding: 0;
  margin: 0 0 0.5rem 0;
  color: var(--themeSecondary-color);
  font-family: coolFont;
  letter-spacing: 0.05rem;
}
.tl-item-year-left {
  margin-right: 10%;
}
.tl-item-year-right {
  margin-left: 10%;
}
.tl-item {
  text-align: left;
  border-width: 0.2rem;
  border-color: var(--themePrimary-color);
  border-style: solid;
  padding: 0;
  flex: 8;
  background-color: var(--themeSecondary-color);
}
.tl-item-left {
  border-radius: 0.5rem 0 0.5rem 0.5rem;
}
.tl-item-right {
  border-radius: 0 0.5rem 0.5rem 0.5rem;
}
.tl-item-title {
  margin: 0;
  color: var(--font2-color);
  font-size: 1rem;
  letter-spacing: 0.025rem;
  line-height: 1.5;
  padding: 0.5rem;
  -webkit-box-shadow: 0 0.2rem 0.3rem -0.15rem #111;
  box-shadow: 0 0.2rem 0.3rem -0.15rem #111;
}
.tl-item-text {
  margin: 0;
  padding: 0.75rem 0.5rem 1rem 0.5rem;
  font-size: 0.9rem;
  line-height: 1.3;
  color: var(--font2-color);
}

@media only screen and (max-width: 600px) {
  .tl-header {
    text-align: left;
  }
  .tl-right-container {
    margin-top: -1rem;
  }
  .tl-item-wrapper {
    margin-top: 5rem;
  }
}
