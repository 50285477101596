.navbar-title-landing {
  position: fixed;
  color: var(--themePrimary-color);
  letter-spacing: 0.1rem;
  font-size: 1.2rem;
  line-height: 1.3;
  font-size: 1.6rem;
  padding: 0;
  margin: 1rem;
  font-family: coolFont;
  width: 100%;
  text-align: center;
}
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  font-family: coolFont;
  z-index: 10;
  align-items: center;
  transition: 0.3;
  -webkit-transition: 0.3s;
}
.navbar-static {
  padding: 0;
  height: var(--navbar-height);
}
.navbar-home-container {
  flex: 1;
  text-align: center;
}
.navbar-home-container-mobile {
  text-align: left;
  flex: 3;
}
.navbar-home {
  color: var(--themePrimary-color);
  letter-spacing: 0.1rem;
  font-size: 1.2rem;
  line-height: 1.2;
  padding: 0;
  margin: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.navbar-home-mobile {
  font-size: 1.4rem;
  margin: 0 2rem;
}
.navbar-home:hover {
  letter-spacing: 0.11rem;
}
.navbar-link-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 2;
  transition: 0.3;
  -webkit-transition: 0.3s;
}
.navbar-link {
  font-weight: bold;
  color: var(--themePrimary-color);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  text-align: center;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
}
.navbar-link:hover,
.navbar-home:hover {
  color: var(--themePrimary-hover);
}
.navbar-contact-btn-container {
  flex: 1;
  text-align: center;
  transition: 0.3;
  -webkit-transition: 0.3s;
}
.navbar-contact-btn-container-mobile {
  text-align: right;
  flex: 2;
}
.navbar-contact-btn-mobile {
  margin: 0 1rem;
}
.navbar-link-underline {
  margin-top: 0.1rem;
  width: 100%;
  height: 0.2rem;
  border-radius: 0 1rem 0 1rem;
  background-color: transparent;
  transition: 0.3;
  -webkit-transition: 0.3s;
}
.navbar-link-underline-acive {
  background-color: var(--themePrimary-color);
}
.navbar-link-acive {
  color: var(--themePrimary-color);
}
.navbar-link-acive {
  color: var(--themePrimary-color);
}

.navmenu-mobile-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--themeSecondary-transparent);
  z-index: 9;
  opacity: 0;
  display: none;
  transition: ease-in-out 0.35s;
  -webkit-transition: ease-in-out 0.35s;
}
.navbar-mobile-link-container {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  list-style: none;
  padding: 1rem;
  text-align: center;
}
.navbar-mobile-link-container li {
  font-size: 1.2rem;
  letter-spacing: 0.15rem;
  font-weight: bold;
  margin: 2rem 0;
  font-family: coolFont;
  transition: ease-in-out 0.35s;
  -webkit-transition: ease-in-out 0.35s;
}
.navbar-home-icon {
  width: 2.7rem;
}
.navbar-mobile-link {
  padding: 1rem 3rem;
  color: var(--font2-color);
}
.navbar-mobile-link:hover {
  color: var(--themePrimary-hover);
}
.navbar-mobile-link-font {
  padding: 0.6rem 1rem;
}
.navbar-mobile-link-font-acive {
  border-bottom: 0.25rem solid var(--themePrimary-color);
  border-radius: 25%;
  color: var(--themePrimary-color);
}
.navbar-mobile-link-container li:nth-child(odd) {
  transform: translateX(-75%);
}
.navbar-mobile-link-container li:nth-child(even) {
  transform: translateX(75%);
}
.navbar-mobile-link-container-show li:nth-child(1n) {
  transform: translateX(0);
}
.navbar-mobile-menu-btn {
  position: fixed;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--themePrimary-color);
  border: none;
  border-radius: 25%;
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  display: flex;
  text-align: center;
  justify-content: space-around;
  flex-direction: column;
  transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  z-index: 10;
  -webkit-box-shadow: 0 0.25rem 1.25rem 0 #111;
  box-shadow: 0 0.25rem 1.25rem 0 #111;
}
.navbar-mobile-menu-btn-transformed {
  border-radius: 50%;
}
.navbar-mobile-menu-btn:hover {
  background-color: var(--themePrimary-hover);
}
.navbar-mobile-menu-btn div {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 0.4rem;
  border-radius: 0.5rem;
  transition: ease-in-out 0.35s;
  -webkit-transition: ease-in-out 0.35s;
}
.navbar-mobile-menu-btn-transformed div:nth-child(1) {
  transform: translateY(1rem) rotate(225deg);
}
.navbar-mobile-menu-btn-transformed div:nth-child(2) {
  opacity: 0;
}
.navbar-mobile-menu-btn-transformed div:nth-child(3) {
  transform: translateY(-1rem) rotate(-225deg);
}

@media only screen and (max-width: 960px) {
  .navbar-home {
    font-size: 1.1rem;
  }
  .navbar-link {
    font-size: 0.9rem;
  }
}
