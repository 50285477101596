.projects-section {
  max-width: 100%;
  padding: 2rem 1rem 10rem 1rem;
}
.project-item {
  background-color: var(--themeSecondary-color);
  border: 0.2rem solid var(--themePrimary-color);
  border-radius: 0.5rem;
  text-align: left;
  padding: 1rem;
  margin: 4rem auto 0 auto;
  max-width: 960px;
  color: var(--font2-color);
}
.project-item h2 {
  margin: 0;
  font-size: 1.4rem;
  display: inline;
}
.project-item-link,
.project-item-inline-link {
  padding: 0.5rem 0.75rem 0.5rem 0;
  transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
}
.project-item-link:hover {
  color: var(--themePrimary-hover);
}
.project-item p {
  font-size: 1rem;
  line-height: 1.4;
}
.project-item-inline-link {
  text-decoration: underline;
}
.project-item-inline-link:hover {
  opacity: 0.7;
}
.project-carusel-center {
  position: relative;
  width: 100%;
  text-align: center;
}
.project-carusel-slider {
  position: relative;
  margin: 1rem auto;
  width: 80%;
  text-align: center;
  padding: 1rem 0;
  vertical-align: middle;
}
.project-carusel-content-wrapper {
  position: relative;
  height: 15rem;
  width: 100%;
}
.project-carusel-img,
.project-carusel-video {
  position: absolute;
  margin: auto;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  max-height: 15rem;
  height: auto;
  max-width: 95%;
  width: auto;
  margin-top: 0;
}
.project-carusel-video {
  border-radius: 0.4rem;
}
.project-carusel-error {
  font-size: 1.1rem;
  opacity: 0.5;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #fff;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #fff;
}

.project-page {
  margin: 10rem auto 10rem auto;
  max-width: 1400px;
  padding: 0 0.5rem;
}
.project-title,
.project-subsection-title,
.project-subsubsection-title {
  color: var(--font1-color);
  letter-spacing: 0.05rem;
}
.project-title {
  font-size: 1.7rem;
  margin: 0 0 1.5rem 0;
}
.project-subsection-title {
  font-size: 1.4rem;
  margin: 0;
}
.project-subsubsection-title {
  font-size: 1.1rem;
  margin: 0;
}
.project-page .header-section {
  margin-bottom: 3rem;
}
.project-page .header-section .pro-ductive-title-logo {
  position: relative;
  top: 0.5rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  width: 2.5rem;
  -webkit-box-shadow: 0 0.1rem 0.3rem -0.1rem #111;
  box-shadow: 0 0.1rem 0.3rem -0.1rem #111;
}
.project-page .header-section p {
  max-width: 960px;
  margin: 0 auto;
  font-size: 1.15rem;
}

.project-page .split-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
}
.project-page .split-section div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.project-page .split-section .main-content {
  flex: 5;
}
.project-page .split-section .sub-content {
  flex: 4;
}
.project-page .split-section .main-content p {
  font-size: 1rem;
  padding: 0;
  margin: 0.5rem;
  line-height: 1.2rem;
}
.project-page .split-section .sub-content img,
.project-page .split-section .sub-content video {
  position: relative;
  margin: auto;
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
}
.project-page .split-section .sub-content .pro-ductive-img1 {
  max-width: 28rem;
}
.project-page .split-section .sub-content .pro-ductive-img2 {
  max-width: 13rem;
}
.project-page .split-section .sub-content .pro-ductive-img3 {
  max-width: 21rem;
}
.project-page .split-section .sub-content .pro-ductive-img4 {
  max-width: 22rem;
  border-radius: 1rem;
}
.project-page .split-section .main-content a {
  color: var(--themePrimary-color);
}
.project-page .split-section .main-content a:hover {
  color: var(--themePrimary-hover);
}
.project-page .split-section .main-content .download-app-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}
.project-page .split-section .main-content .download-app-wrapper img {
  height: 4rem;
  margin: 0 1rem;
}
.project-page .split-section .main-content ul,
.project-page .split-section .main-content ol {
  text-align: left;
  margin: 0.5rem auto;
}
.project-page .split-section .main-content li {
  margin-bottom: 0.5rem;
}
.project-page .header-section .webbcv-title-logo {
  width: 12rem;
}
.project-page .split-section .sub-content .webbcv-img1 {
  max-width: 26rem;
}
.project-page .split-section .sub-content .webbcv-img2 {
  border-radius: 1rem;
  max-width: 23rem;
}
.project-page .split-section .sub-content .webbcv-img3 {
  border-radius: 1rem;
  max-width: 35rem;
}
.project-page .split-section .sub-content .webbcv-img4 {
  max-width: 25rem;
}
.project-page .split-section .sub-content .webbcv-img5 {
  max-width: 13rem;
}
.project-page .split-section .main-content .webbcv-link {
  font-size: 1.3rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 800px) {
  .project-page .split-section {
    flex-direction: column;
    margin: 3rem 0;
  }
  .project-page .split-section .sub-content {
    margin: 2rem;
  }
  .project-page .split-section:nth-child(odd) {
    flex-direction: column-reverse;
  }
  .project-page .split-section .sub-content .pro-ductive-img1 {
    max-width: 20rem;
  }
  .project-page .split-section .sub-content .pro-ductive-img2 {
    max-width: 10rem;
  }
  .project-page .split-section .sub-content .pro-ductive-img3 {
    max-width: 19rem;
  }
  .project-page .split-section .sub-content .pro-ductive-img4 {
    max-width: 18rem;
    border-radius: 1rem;
  }
}
