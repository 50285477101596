.landing-master-container {
  padding: 0 0 10rem 0;
  overflow: hidden;
}
.dynamic-bkg {
  position: relative;
  height: 100vh;
  width: 100%;
  margin: 0;
  margin-bottom: calc(var(--navbar-height) * -1);
  top: calc(var(--navbar-height) * -1);
  padding: 0;
  overflow: hidden;
}
.floating-text-center {
  position: relative;
  width: 0;
  left: 50%;
  top: 50%;
}
.floating-text-lbl {
  position: absolute;
  width: auto;
  z-index: -2;
  white-space: nowrap;
  color: #666;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.landing-blur-overlay {
  opacity: 0.6;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  backdrop-filter: blur(1px);
}
.landing-intro-container {
  position: relative;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}
.landing-intro-pp-border {
  position: relative;
  margin: 0 auto;
  width: 14rem;
  height: 14rem;
  border: 0.5rem solid var(--themeSecondary-color);
  border-radius: 20rem;
}
.landing-intro-pp {
  width: 103%;
  height: 103%;
  margin: -1.5% 0 0 -1.5%;
  border-radius: 20rem;
  overflow: hidden;
}
.landing-section {
  margin: 330px auto 0 auto;
}
.landing-link-wrapper {
  position: absolute;
  width: 100%;
  top: -5.5rem;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}
.landing-link {
  font-family: coolFont;
  margin: 0 2rem;
  padding: 0.5rem 1rem;
  color: var(--themePrimary-color);
  font-size: 1.3rem;
}
.landing-category-link:hover,
.landing-link:hover {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  color: var(--themePrimary-hover);
}

.landing-swirrl-container {
  margin-top: -14rem;
}

.landing-link-level1 {
  transform: translateY(2.5rem);
}
.landing-svg-container {
  position: relative;
  margin: 0;
  padding: 0;
}
.landing-svg-container-top {
  top: 0;
  margin-bottom: -0.2rem;
}
.landing-svg-container-bottom {
  margin-top: -0.2rem;
}
.landing-svg-path {
  fill: var(--themePrimary-color);
  stroke-width: 0;
  margin: 0;
}
.landing-intro-wrapper {
  position: relative;
  background-color: var(--themePrimary-color);
  margin-top: -0.25rem;
}
.landing-intro-section {
  margin: 0 auto;
  padding: 0;
}
.landing-intro-conatiner {
  position: relative;
  margin: 0 auto;
  padding: 1.5rem 1rem 0.5rem 1rem;
}
.landing-intro-subtitle {
  letter-spacing: 0.05rem;
  line-height: 1.6;
  font-family: coolFont;
  font-size: 1rem;
  color: var(--font2-color);
}
.landing-intro-subtitle span {
  opacity: 0.6;
}
.landing-intro-p {
  font-size: 1rem;
  line-height: 1.3;
  color: var(--font2-color);
}

.landing-contact-section {
  margin: 0 auto;
  padding: 0 0 1rem 0;
  text-align: center;
}
.landing-contact-section h3 {
  font-size: 1.3rem;
  color: var(--themeSecondary-color);
}

.landing-category-section {
  max-width: 100%;
  padding: 2rem 0;
}
.landing-category-link {
  position: relative;
  font-family: coolFont;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  padding: 0.5rem 0.25rem 0.05rem 0.25rem;
  color: var(--themePrimary-color);
  border-bottom: 0.15rem solid transparent;
  border-radius: 0.25rem 0.5rem;
}
.landing-cv-img {
  position: relative;
  width: 30rem;
  height: 20rem;
  margin: 1rem 0 0 0;
  padding: 0;
  object-fit: cover;
  object-position: 100% 0;
  border-radius: 0.5rem;
}
.landing-cv-img:hover {
  transform: translateY(-0.15rem);
  transition: 0.5s;
  -webkit-transition: 0.5s;
}
.landing-cv-link,
.landing-timeline-link,
.landing-projects-link {
  position: relative;
  font-size: 1.2rem;
  text-decoration: underline;
  padding: 0.5rem 1rem;
  top: 1rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  color: var(--themePrimary-color);
}
.landing-cv-link:hover,
.landing-timeline-link:hover,
.landing-projects-link:hover {
  letter-spacing: 0.03rem;
  color: var(--themePrimary-hover);
}

.landing-project-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;
}
.landing-project-divider {
  height: 1rem;
  background-color: var(--themePrimary-color);
}
.landing-project-container div {
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;
  color: var(--font2-color);
}
.landing-project-square {
  position: relative;
  flex: 5;
  flex-direction: column;
  background-color: var(--themePrimary-color);
  padding: 1rem 0;
}
.landing-project-img-container {
  position: relative;
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.landing-project-container img {
  position: relative;
  object-fit: contain;
}
.landing-project-square-top-right {
  border-top-right-radius: 0.5rem;
}
.landing-project-square-bottom-right {
  border-bottom-right-radius: 0.5rem;
}
.landing-project-container > div:nth-of-type(1) img {
  height: 11rem;
  padding: 1.5rem;
  border-radius: 2.5rem;
}
.landing-project-container > div:nth-of-type(2) img {
  height: 12rem;
  padding: 1.5rem;
}
.landing-project-container > div:nth-of-type(3) img {
  height: 12rem;
  padding: 1rem;
}
.landing-project-square h3 {
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}
.landing-project-square p {
  font-size: 1rem;
  line-height: 1.2;
  padding: 0 4rem;
  margin: 1rem 1rem;
}
.landing-project-inline-link {
  text-decoration: underline;
  transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  margin-left: 0.5rem;
}
.landing-project-inline-link:hover {
  opacity: 0.7;
}
.tl-main-container-landing {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 960px;
}
.tl-line-landing {
}
.tl-line-dashed {
  position: absolute;
  width: 0;
  height: 2rem;
  bottom: 0;
  margin-bottom: -2.5rem;
  border-left: 0.45rem dotted var(--themePrimary-color);
}
.landing-timeline-link {
  top: 4rem;
}

@media only screen and (max-width: 700px) {
  .landing-project-container > div:nth-of-type(1) {
    flex-direction: column-reverse;
  }
  .landing-project-container > div:nth-of-type(1) {
    flex-direction: column;
  }
  .landing-project-container > div:nth-of-type(2) {
    flex-direction: column-reverse;
    margin-top: 0rem;
  }
  .landing-project-divider {
    display: none;
  }
  .landing-project-square p {
    padding: 0 1rem;
  }
  .landing-project-square2,
  .landing-project-square1 {
    border-radius: 0;
  }
}
@media only screen and (max-width: 600px) {
  .landing-link {
    margin: 0 1.75rem;
    font-size: 1.2rem;
  }
  .landing-intro-pp-border {
    width: 12rem;
    height: 12rem;
  }
  .landing-link-wrapper {
    top: -5.25rem;
  }
}
@media only screen and (max-width: 500px) {
  .landing-link {
    margin: 0 1.5rem;
    font-size: 1.15rem;
  }
  .landing-intro-pp-border {
    width: 11rem;
    height: 11rem;
    border: 0.5rem solid var(--themeSecondary-color);
  }
  .landing-link-wrapper {
    top: -5rem;
  }
}
@media only screen and (max-width: 450px) {
  .landing-link {
    margin: 0 1.25rem;
    font-size: 1.1rem;
  }
  .landing-intro-pp-border {
    width: 10rem;
    height: 10rem;
    border: 0.4rem solid var(--themeSecondary-color);
  }
  .landing-link-wrapper {
    top: -4.75rem;
  }
}
@media only screen and (max-width: 400px) {
  .landing-link {
    margin: 0 1rem;
    font-size: 1.05rem;
  }
  .landing-intro-pp-border {
    width: 9rem;
    height: 9rem;
  }
  .landing-link-wrapper {
    top: -4.5rem;
  }
}
/* HEIGHT*/
@media only screen and (max-height: 450px) {
  .landing-link {
    margin: 0 1.75rem;
    font-size: 1.3rem;
  }
  .landing-intro-pp-border {
    width: 13rem;
    height: 13rem;
  }
  .landing-link-wrapper {
    top: -4.75rem;
  }
}
